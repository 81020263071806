@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1.15;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1, .h1 {
  @apply tracking-[0.08em] text-[65px] leading-[1.5];
}

.button {
  @apply text-white bg-red-700 hover:bg-red-900 focus:ring-4 focus:ring-red-300 focus:rounded-lg font-medium rounded-lg text-sm px-5 py-2.5 disabled:bg-gray-400 disabled:hover:bg-gray-400;
}

input {
  @apply border-slate-300 border-[1px] rounded border-solid disabled:border-slate-200 disabled:text-slate-200;
}

.btn_readmore {
  @apply inline  font-semibold  py-2 px-4 border border-solid   rounded-bl rounded-tl;
}
.btn_readmore-light {
  @apply hover:bg-gray-500 text-gray-700 hover:text-white border-gray-500 hover:border-transparent bg-transparent;
}
.btn_readmore-dark {
  @apply hover:bg-gray-100 text-gray-100 hover:text-gray-900 border-gray-100 hover:border-transparent bg-transparent;
}

input[type=range] {
  @apply accent-red-600 [-webkit-appearance:slider-vertical] [writing-mode:bt-lr];
}

.badge {
  @apply text-[0.75rem] p-2 rounded-2xl;
}
.badge-success {
  @apply transition-all ease-in-out duration-300 border-[1px] border-green-500 border-solid text-green-500 hover:border-none hover:text-white hover:bg-green-500 cursor-default;
}

.form-group {
  @apply flex items-center pb-4;
}
.form-group label {
  @apply pr-5;
}
.form-group input, .form-group select {
  @apply w-[30%] p-2;
}

.nav {
  @apply z-[1002] overflow-hidden transition-all ease-in-out duration-300;
}

.navbar {
  @apply overflow-hidden flex justify-between h-[70px] lg:h-[100px] shadow-none bg-transparent px-3 xl:px-0 mx-[auto] max-w-[1400px];
}
.navbar-logo {
  @apply flex justify-center items-center;
}
.navbar-logo img {
  @apply h-[40px] lg:h-[60px];
}
.navbar-menu_container {
  @apply fixed bg-white lg:bg-transparent z-[51] top-0 w-[100vw] overflow-hidden left-[100%] flex flex-col h-[100vh] lg:flex lg:justify-around lg:static lg:flex-row lg:top-auto lg:left-auto lg:w-auto lg:h-auto transition-all ease-in-out lg:items-center duration-1000;
}
.navbar-menu_container.show {
  @apply left-[0%];
}
.navbar-menu_container .closemobile {
  height: 70px;
  @apply lg:hidden flex justify-end items-center pr-4;
}
.navbar-menu__item {
  @apply px-2.5 py-4 lg:py-0 leading-[1.5] cursor-pointer lg:flex lg:items-center;
}
.navbar-menu__item span {
  @apply hover:text-red-700;
}
.navbar .mobilemenu_btn {
  @apply flex justify-center items-center lg:hidden;
}
.navbar .mobilemenu_btn .hamburger_btn {
  @apply cursor-pointer block;
}

.herobanner {
  @apply relative h-[400px] lg:h-[800px] xl:h-[1000px];
}
.herobanner__container {
  @apply relative h-[100%];
}
.herobanner__container video {
  @apply absolute top-0 left-0 block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.herobanner__content {
  @apply absolute pt-16 lg:pt-44 w-[100%];
}
.herobanner__content h1 {
  @apply text-[24px] lg:text-[65px] text-center;
}
.herobanner__content p {
  @apply text-[16px] lg:text-[24px] tracking-[0.05em] leading-[1.8em] text-center;
}

.numbersection {
  @apply relative min-h-[300px] lg:h-[500px];
}
.numbersection-bg {
  @apply absolute w-[100%] h-[100%];
}
.numbersection-bg img {
  @apply w-[100%]  h-[100%] object-cover;
}
.numbersection-container {
  @apply pt-7 relative z-10 flex flex-col lg:flex-row px-2 lg:mx-[auto] max-w-[1400px] h-[100%];
}
.numbersection-img {
  @apply flex justify-center items-center relative w-[100%] lg:w-[50%] h-[100px] lg:h-[100%];
}
.numbersection-img img {
  @apply h-[100%] lg:h-[300px];
}
.numbersection-content {
  @apply flex flex-row flex-wrap w-[100%] lg:w-[50%];
}
.numbersection-card {
  @apply w-[50%] px-[60px] py-[30px];
}
.numbersection-card__number {
  @apply text-[40px] tracking-[0.08em] leading-[1.5] text-white;
}
.numbersection-card__div {
  @apply relative w-[100%] my-[20px] h-[6px] before:absolute before:top-[5px] before:left-0 before:h-[1px] before:w-[100%] before:bg-white before:z-10 before:content-[""];
}
.numbersection-card__div .whiteline {
  @apply bg-white h-[1px];
}
.numbersection-card__div .boldline {
  @apply absolute top-[0px] left-0 h-[6px] w-[50px] bg-white;
}
.numbersection-card__text {
  @apply text-[14px] tracking-[0.16em] text-white leading-[1.5];
}

.textcontainer {
  @apply bg-black;
}
.textcontainer-wrapper {
  @apply relative px-3 xl:px-0 mx-auto max-w-[1400px] flex flex-col lg:flex-row;
}
.textcontainer-content {
  @apply relative w-[100%] lg:w-[40%] text-white my-6 px-0 lg:px-20 pt-12 before:absolute before:content-[""] before:top-0 before:left-0 before:w-[1px] before:h-[100%] before:bg-white before:hidden lg:before:block;
}
.textcontainer-content__title {
  @apply relative tracking-[0.08em] text-[24px] leading-[1.8em] pb-5 lg:mb-12 pt-0 lg:pt-6 before:absolute before:content-[""] before:top-0 before:left-[-5rem] before:w-[3px] before:h-[100%] before:bg-white before:hidden lg:before:block overflow-visible;
}
.textcontainer-content__text {
  @apply tracking-[0.04em] text-[16px] leading-[1.8em];
}
.textcontainer-content__text p {
  @apply pb-7;
}
.textcontainer-img {
  @apply flex items-center lg:w-[100%] relative overflow-hidden;
}
.textcontainer-img img {
  @apply w-[100%] h-[auto] object-contain object-[50%_50%];
}

.grouptext {
  @apply bg-white pt-11 lg:pt-32;
}
.grouptext-wrapper {
  @apply relative px-3 xl:px-0 mx-auto max-w-[1400px] flex flex-col pb-10;
}
.grouptext-head {
  @apply relative pl-20 mb-20 before:absolute before:content-[""] before:h-full before:w-[1px] before:bg-black before:left-0;
}
.grouptext-head__heading {
  @apply pb-12;
}
.grouptext-head__heading h4 {
  @apply text-[14px] tracking-[0.16em];
}
.grouptext-head__subheading {
  @apply relative pb-8 before:absolute before:content-[""] before:h-full before:w-[4px] before:bg-black before:left-[-5rem] overflow-visible;
}
.grouptext-head__title h2 {
  @apply text-[24px] tracking-[0.08em] leading-[1.8em] font-[700] pb-3;
}
.grouptext-head__subtitle h3 {
  @apply text-[24px] tracking-[0.08em] leading-[1.3em];
}
.grouptext-item {
  @apply flex flex-col lg:odd:flex-row lg:even:flex-row-reverse;
}
.grouptext-item__content {
  @apply pb-6 lg:pb-0 w-full lg:w-[50%];
}
.grouptext-item__title {
  @apply pb-8;
}
.grouptext-item__title h2 {
  @apply text-[28px] lg:text-[40px] leading-[1.5em] tracking-[0.08em];
}
.grouptext-item__text {
  @apply pb-10;
}
.grouptext-item__text span {
  @apply tracking-[0.05em] leading-[1.8em] text-[16px];
}
.grouptext-item__img {
  @apply w-full lg:w-[50%] h-[500px] p-0 lg:p-5;
}
.grouptext-item__img img {
  @apply h-full w-full lg:w-full;
  -o-object-fit: cover;
     object-fit: cover;
}

.bgtextcontainer {
  @apply min-h-[500px] relative;
}
.bgtextcontainer-bg {
  @apply h-full w-full absolute -z-10  overflow-hidden;
}
.bgtextcontainer-bg img {
  @apply w-full object-cover h-full;
}
.bgtextcontainer-container {
  @apply py-[4rem] px-3 xl:px-0 flex flex-col lg:flex-row lg:mx-auto max-w-[1400px];
}
.bgtextcontainer-wrapper {
  @apply bg-black w-full lg:w-[50%] xl:w-[40%] p-8 rounded-2xl;
}
.bgtextcontainer-content {
  @apply relative pl-14 before:content-[""] before:absolute before:w-[1px] before:h-[80%] before:bg-white before:top-0 before:left-0;
}
.bgtextcontainer-title, .bgtextcontainer-text, .bgtextcontainer-heading {
  @apply text-white;
}
.bgtextcontainer-heading {
  @apply text-[14px] tracking-[0.16em] leading-normal pb-9;
}
.bgtextcontainer-text {
  @apply text-[16px] tracking-[0.04em] leading-[1.8em] pb-10;
}
.bgtextcontainer-text p {
  @apply pb-8;
}
.bgtextcontainer-title {
  @apply relative overflow-visible text-[24px] tracking-[0.08em] leading-[1.8em] mb-16 pb-10 before:content-[""] before:absolute before:w-[4px] before:h-full before:bg-white before:top-0 before:-left-14;
}

.contactus {
  @apply bg-center bg-cover bg-fixed min-h-[400px];
}
.contactus-container {
  @apply py-[4rem] px-3 xl:px-0 flex flex-col lg:flex-row lg:mx-auto max-w-[1400px];
}
.contactus-wrapper {
  @apply bg-black w-full py-10 px-10 rounded lg:w-[50%];
}
.contactus-map {
  @apply w-full lg:w-[50%] h-auto p-3;
}
.contactus-map .leaflet-container {
  @apply h-[300px] lg:h-[70%] rounded-lg;
}
.contactus-info {
  @apply py-4;
}
.contactus-info__wrapper {
  @apply rounded p-3 bg-white;
}
.contactus-info__content {
  @apply pb-2 px-4;
}
.contactus-err {
  @apply text-white p-4 rounded mb-6;
}
.contactus-title {
  @apply relative mb-4 pb-2 text-4xl text-white;
}
.contactus_form-group {
  @apply flex flex-col mb-[2.5rem] relative;
}
.contactus_form-group.form_input {
  @apply after:absolute after:w-0 after:h-[2px] after:bg-red-600 after:left-0 after:bottom-0 after:transition-all focus-within:after:w-full;
}
.contactus_form-label {
  @apply text-white pb-1;
}
.contactus_form-input:not([type=checkbox]), .contactus_form-input:not([type=radio]), .contactus_form-input:not([type=color]), .contactus_form-input:not([type=button]), .contactus_form-input:not([type=date]), .contactus_form-input:not([type=datetime-local]), .contactus_form-input:not([type=range]), .contactus_form-input:not([type=reset]), .contactus_form-input:not([type=submit]), .contactus_form-input:not([type=search]), .contactus_form-input:not([type=time]) {
  @apply pt-2 px-1 pb-1 bg-transparent border-b-[1px] border-b-white outline-none caret-white text-white relative;
}
.contactus_form-textarea {
  @apply pt-2 pb-1 px-2 bg-transparent border-[1px] border-white rounded-sm outline-none caret-white text-white text-[16px] tracking-[0.04em] leading-[1.3] transition-all focus:border-red-700;
}

.locationcontainer {
  @apply h-[400px] lg:h-[600px] relative;
}
.locationcontainer .leaflet-container {
  @apply h-full;
}

.apply-map {
  @apply w-full h-auto;
}
.apply-map .leaflet-container {
  @apply h-[500px] rounded-lg;
}

.modalsite {
  @apply absolute opacity-0 hidden w-full h-full top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.3)] z-[1001];
  transition: all 0.3s ease-in-out;
}
.modalsite.active {
  @apply block opacity-100;
}
.modalsite-container {
  @apply bg-white w-full md:w-[50%] lg:w-[400px] h-full overflow-auto absolute top-0 left-[-100%] md:left-[-50%] lg:left-[-400px] z-[1002] transition-all ease-in-out duration-300;
}
.modalsite-container.active {
  @apply left-0;
}
.modalsite-img {
  @apply h-[100px] flex justify-center pb-6;
}
.modalsite-img img {
  @apply h-full;
}
.modalsite-btngroup {
  @apply flex justify-between pb-8;
}
.modalsite-title {
  @apply flex items-center;
}
.modalsite-title span {
  @apply font-bold;
}
.modalsite-desc {
  @apply pb-10;
}
.modalsite-number {
  @apply flex justify-around;
}
.modalsite-number__container {
  @apply flex flex-col justify-center w-[120px] py-3 border-black  border border-solid rounded;
}
.modalsite-number__val, .modalsite-number__title {
  @apply flex justify-center;
}
.modalsite-number__title span {
  @apply text-slate-400;
}
.modalsite-number__val span {
  @apply text-[1.8rem] lg:text-[3rem] text-red-700 font-bold;
}
.modalsite-third {
  @apply flex justify-center;
}
.modalsite-third__wrapper {
  @apply w-[50%] border border-slate-200 border-solid py-4 hover:bg-slate-200;
}
.modalsite-third__label {
  @apply flex justify-center;
}
.modalsite-third__val {
  @apply flex justify-center;
}
.modalsite-third__val span {
  @apply text-red-600 font-bold text-2xl;
}
.modalsite-forth {
  @apply px-6 pt-4;
}
.modalsite-forth__wrapper {
  @apply flex pb-4;
}
.modalsite-forth__icon {
  @apply pr-2 w-[10%];
}
.modalsite-forth__label {
  @apply w-[80%];
}
.modalsite-forth__label.address span {
  @apply font-bold;
}
.modalsite-fifth {
  @apply pb-8;
}
.modalsite-fifth__title {
  @apply font-bold text-xl px-2;
}
.modalsite-fifth__wrapper {
  @apply flex py-4 px-2 border-gray-200 border-[1px] border-solid;
}
.modalsite-fifth__logo {
  @apply w-[10%] flex items-center;
}
.modalsite-fifth__label {
  @apply w-[70%] flex items-center text-sm md:text-[16px];
}
.modalsite-fifth__name {
  @apply font-bold text-red-600;
}
.modalsite-fifth__badge {
  @apply w-[20%] flex items-center;
}

.booking-bg {
  @apply bg-scroll flex justify-center items-center bg-center bg-no-repeat bg-cover h-[100px] md:h-[200px];
}
.booking-bg__sitename {
  @apply text-white font-bold text-2xl md:text-4xl lg:text-7xl;
}
.booking-main {
  @apply w-full lg:w-[70%] min-h-[100px] p-4;
}
.booking-custinfo__item {
  @apply flex pb-2;
}
.booking-custinfo__label {
  @apply w-[20%];
}
.booking-custinfo__val {
  @apply w-[80%];
}
.booking-summary {
  @apply hidden lg:block w-[30%] min-h-[100px] p-4;
}
.booking-summary__detail-title {
  @apply pb-6 text-xl font-bold;
}
.booking-summary__detail-wrapper {
  @apply flex justify-between pb-2;
}
.booking-summary__detail-wrapper .booking-summary__detail-val {
  @apply font-bold;
}
.booking-summary__detail-info {
  @apply pb-3;
}
.booking-summary__detail-total {
  @apply flex justify-between pb-4;
}
.booking-summary__detail-total .booking-summary__detail-label, .booking-summary__detail-total .booking-summary__detail-val {
  @apply text-lg;
}
.booking-summary__detail-total .booking-summary__detail-val {
  @apply font-bold;
}

.order-bg {
  @apply h-[300px] lg:h-[450px] relative;
}
.order-bg__video video {
  @apply absolute top-0 left-0 block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.order-bg__content {
  @apply absolute w-[100%] top-0 px-2 lg:px-6 flex flex-col;
}
.order-bg__content span {
  @apply text-[16px] lg:text-[24px] tracking-[0.05em] leading-[1.8em] text-center text-white;
}
.order-bg__img {
  @apply h-[200px] lg:h-[300px] flex justify-center;
}
.order-bg__img img {
  @apply h-full;
}

.footer {
  @apply bg-white;
}
.footer-container {
  @apply py-[2rem] px-8 xl:px-0 lg:mx-auto max-w-[1400px];
}
.footer-wrapper {
  @apply flex justify-between;
}
.footer-text {
  @apply flex items-center;
}
.footer-logo {
  @apply h-[30px] xl:h-[60px];
}
.footer-logo img {
  @apply h-full w-full;
}

#babylon-canvas {
  width: 100%;
  height: 100%;
}

.swiper {
  width: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  @apply shadow-xl;
}
@media (min-width: 1024px) {
  .swiper-slide {
    width: 400px !important;
    height: 400px !important;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  height: 400px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}/*# sourceMappingURL=index.css.map */