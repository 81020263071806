@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');


// css reset :: begin
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.15;
    font-family: 'Montserrat', sans-serif;
    overflow-x:hidden;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
// css reset :: end


// utilities :: begin
h1,.h1{
    @apply tracking-[0.08em] text-[65px] leading-[1.5]
}

.button{
    @apply text-white bg-red-700 hover:bg-red-900 focus:ring-4 focus:ring-red-300 focus:rounded-lg font-medium rounded-lg text-sm px-5 py-2.5 disabled:bg-gray-400 disabled:hover:bg-gray-400;
}

input{
    @apply border-slate-300 border-[1px] rounded border-solid disabled:border-slate-200 disabled:text-slate-200;
}

.btn_readmore{
    @apply inline  font-semibold  py-2 px-4 border border-solid   rounded-bl rounded-tl;

    &-light{
        @apply hover:bg-gray-500 text-gray-700 hover:text-white border-gray-500 hover:border-transparent bg-transparent;
    }

    &-dark{
        @apply hover:bg-gray-100 text-gray-100 hover:text-gray-900 border-gray-100 hover:border-transparent bg-transparent;
    }
}

input[type=range] {
    @apply accent-red-600 [-webkit-appearance:slider-vertical] [writing-mode:bt-lr];
}

.badge{
    @apply text-[0.75rem] p-2 rounded-2xl;
    &-success{
        @apply transition-all ease-in-out duration-300 border-[1px] border-green-500 border-solid text-green-500 hover:border-none hover:text-white hover:bg-green-500 cursor-default;
    }
}

.form{
    &-group{
        @apply  flex items-center pb-4;

        label{
            @apply pr-5;
        }

        input,select{
            @apply w-[30%] p-2;
        }

    }
}

// utilities :: end


// navbar :: begin
.nav {
    @apply  z-[1002] overflow-hidden transition-all ease-in-out duration-300;
}
.navbar{
    @apply overflow-hidden flex justify-between h-[70px] lg:h-[100px] shadow-none bg-transparent px-3 xl:px-0 mx-[auto] max-w-[1400px];

    
    &-logo{
        @apply flex justify-center items-center;
        img{
            @apply h-[40px] lg:h-[60px];
        }
    }

    &-menu
    {
        &_container{
            
            @apply fixed bg-white lg:bg-transparent z-[51] top-0 w-[100vw] overflow-hidden left-[100%] flex flex-col h-[100vh] lg:flex lg:justify-around lg:static lg:flex-row lg:top-auto lg:left-auto lg:w-auto lg:h-auto transition-all ease-in-out lg:items-center duration-1000;

            &.show{
                @apply left-[0%];
            }

            .closemobile{
                height:70px;
                @apply lg:hidden flex justify-end items-center pr-4;
            }
        }

        &__item {
            @apply px-2.5 py-4 lg:py-0 leading-[1.5] cursor-pointer lg:flex lg:items-center;
            span{
                @apply hover:text-red-700;
            }
        }
    }

    .mobilemenu_btn
    {
        @apply flex justify-center items-center lg:hidden;
        .hamburger_btn
        {
            @apply cursor-pointer block;
        }
    }

}
// navbar :: end

// herobanner :: begin
.herobanner{

    @apply relative h-[400px] lg:h-[800px] xl:h-[1000px];
    &__container{
        @apply relative h-[100%];
        video{
            @apply absolute top-0 left-0 block;
            width:100%;
            height:100%;
            object-fit: cover;
        }
    }

    &__content{
        @apply absolute pt-16 lg:pt-44 w-[100%];
        h1{
            @apply text-[24px] lg:text-[65px] text-center;
        }
        p{
            @apply text-[16px] lg:text-[24px] tracking-[0.05em] leading-[1.8em] text-center
        }
    }
}
// herobanner :: end

// numbersection :: begin
.numbersection {
    @apply relative min-h-[300px] lg:h-[500px];

    &-bg{
        @apply absolute w-[100%] h-[100%];
        img{
            @apply w-[100%]  h-[100%] object-cover
        }
    }

    &-container{
        @apply  pt-7 relative z-10 flex flex-col lg:flex-row px-2 lg:mx-[auto] max-w-[1400px] h-[100%];
    }

    &-img{
        @apply flex justify-center items-center relative w-[100%] lg:w-[50%] h-[100px] lg:h-[100%];
        img{
            @apply h-[100%] lg:h-[300px];
        }
    }

    &-content{
        @apply flex flex-row flex-wrap w-[100%] lg:w-[50%]
    }

    &-card{
        @apply w-[50%] px-[60px] py-[30px];
        &__number{
            @apply text-[40px] tracking-[0.08em] leading-[1.5] text-white;
        }
        &__div{
            @apply relative w-[100%] my-[20px] h-[6px] before:absolute before:top-[5px] before:left-0 before:h-[1px] before:w-[100%] before:bg-white before:z-10 before:content-[''];
            
            .whiteline{
                @apply bg-white h-[1px]
            }
            .boldline{
                @apply absolute top-[0px] left-0 h-[6px] w-[50px] bg-white;
            }
        }
        &__text{
            @apply text-[14px] tracking-[0.16em] text-white leading-[1.5];
        }
    }
}
// numbersection :: end

// textcontainer :: begin
.textcontainer{
    @apply bg-black;
    &-wrapper{
        @apply relative px-3 xl:px-0 mx-auto max-w-[1400px] flex flex-col lg:flex-row;
    }

    &-content{
        @apply relative w-[100%] lg:w-[40%] text-white my-6 px-0 lg:px-20 pt-12 before:absolute before:content-[''] before:top-0 before:left-0 before:w-[1px] before:h-[100%] before:bg-white before:hidden lg:before:block;

        &__title{
            @apply relative tracking-[0.08em] text-[24px] leading-[1.8em] pb-5 lg:mb-12 pt-0 lg:pt-6 before:absolute before:content-[''] before:top-0 before:left-[-5rem] before:w-[3px] before:h-[100%] before:bg-white before:hidden lg:before:block overflow-visible;
        }
        &__text{
            @apply tracking-[0.04em] text-[16px] leading-[1.8em];
            p{
                @apply pb-7;
            }
        }
    }
    &-img{
        @apply flex items-center lg:w-[100%] relative overflow-hidden;
        img{
            // @apply lg:w-[100%] w-[125%] h-[125%] lg:h-[1000px] object-cover object-[center_-112px] lg:object-[-70px_-145px];
            @apply w-[100%] h-[auto] object-contain object-[50%_50%];
        }
    }
}
// textcontainer :: end

// grouptext :: begin
.grouptext{
    @apply bg-white pt-11 lg:pt-32;

    &-wrapper{
        @apply relative px-3 xl:px-0 mx-auto max-w-[1400px] flex flex-col pb-10;
    }

    &-head{
        @apply relative pl-20 mb-20 before:absolute before:content-[''] before:h-full before:w-[1px] before:bg-black before:left-0;
        &__heading{
            @apply pb-12;
            h4{
                @apply text-[14px] tracking-[0.16em]
            }
        }
        &__subheading{
            @apply relative pb-8 before:absolute before:content-[''] before:h-full before:w-[4px] before:bg-black before:left-[-5rem] overflow-visible;
        }
        &__title{
            h2{
                @apply text-[24px] tracking-[0.08em] leading-[1.8em] font-[700] pb-3
            }
        }
        &__subtitle{
            h3{
                @apply text-[24px] tracking-[0.08em] leading-[1.3em]
            }
        }
    }

    &-body{

    }

    &-item{
        @apply flex flex-col lg:odd:flex-row lg:even:flex-row-reverse;

        &__content{
            @apply pb-6 lg:pb-0 w-full lg:w-[50%];
        }

        &__title{
            @apply pb-8;
            h2{
                @apply text-[28px] lg:text-[40px] leading-[1.5em] tracking-[0.08em];
            }
        }

        &__text{
            @apply pb-10;
            span{
                @apply tracking-[0.05em] leading-[1.8em] text-[16px];
            }
        }

        &__img{
            @apply w-full lg:w-[50%] h-[500px] p-0 lg:p-5;
            img{
                @apply h-full w-full lg:w-full;
                object-fit:cover;
            }
        }
    }
}
// grouptext :: end

// bgtextcontainer :: begin
.bgtextcontainer{
    @apply min-h-[500px] relative;


    &-bg{
        @apply h-full w-full absolute -z-10  overflow-hidden;
        img{
            @apply w-full object-cover h-full;
        }
    }

    &-container{
        @apply py-[4rem] px-3 xl:px-0 flex flex-col lg:flex-row lg:mx-auto max-w-[1400px];
    }

    &-wrapper{
        @apply bg-black w-full lg:w-[50%] xl:w-[40%] p-8 rounded-2xl;
    }

    &-content{
        @apply  relative pl-14 before:content-[''] before:absolute before:w-[1px] before:h-[80%] before:bg-white before:top-0 before:left-0;
    }

    &-title,&-text,&-heading{
        @apply text-white;
    }

    &-heading{
        @apply text-[14px] tracking-[0.16em] leading-normal pb-9;
    }

    &-text{
        @apply text-[16px] tracking-[0.04em] leading-[1.8em] pb-10;
        p{
            @apply pb-8;
        }
    }

    &-title{
        @apply relative overflow-visible text-[24px] tracking-[0.08em] leading-[1.8em] mb-16 pb-10 before:content-[''] before:absolute before:w-[4px] before:h-full before:bg-white before:top-0 before:-left-14;
    }
}
// bgtextcontainer :: end

// contactus :: begin
.contactus{
    @apply bg-center bg-cover bg-fixed min-h-[400px];

    &-container{
        @apply py-[4rem] px-3 xl:px-0 flex flex-col lg:flex-row lg:mx-auto max-w-[1400px];
    }

    &-wrapper{
        @apply bg-black w-full py-10 px-10 rounded lg:w-[50%];
    }

    &-map{
        @apply w-full lg:w-[50%] h-auto p-3;
        .leaflet-container{
            @apply h-[300px] lg:h-[70%] rounded-lg;
        }
    }

    &-info{
        @apply py-4;
        &__wrapper{
            @apply rounded p-3 bg-white;
        }
        &__content{
            @apply pb-2 px-4;
        }
    }

    &-err{
        @apply text-white p-4 rounded mb-6;
    }

    &-title{
        @apply relative mb-4 pb-2 text-4xl text-white;
    }

    &_form{
        &-group{
            @apply flex flex-col mb-[2.5rem] relative ;
            &.form_input{
                @apply after:absolute after:w-0 after:h-[2px] after:bg-red-600 after:left-0 after:bottom-0 after:transition-all focus-within:after:w-full;
            }
        }
        &-label{
            @apply text-white pb-1;
        }

        &-input{
            &:not([type=checkbox]),&:not([type=radio]),&:not([type=color]),&:not([type=button]),&:not([type=date]),&:not([type=datetime-local]),&:not([type=range]),&:not([type=reset]),&:not([type=submit]),&:not([type=search]),&:not([type=time])
            {
                @apply pt-2 px-1 pb-1 bg-transparent border-b-[1px] border-b-white outline-none caret-white text-white relative;
            }
        }

        &-textarea{
            @apply pt-2 pb-1 px-2 bg-transparent border-[1px] border-white rounded-sm outline-none caret-white text-white text-[16px] tracking-[0.04em] leading-[1.3] transition-all focus:border-red-700;
        }
    }
}
// contactus :: end

// locationcontainer :: begin
.locationcontainer{
    @apply h-[400px] lg:h-[600px] relative;
    .leaflet-container
    {
        @apply h-full;
    }
}

// apply :: begin
.apply{
    
    &-map{
        @apply w-full h-auto;
        .leaflet-container{
            @apply h-[500px] rounded-lg;
        }
    }
}
// apply :: end

.modalsite
{
    @apply absolute opacity-0 hidden w-full h-full top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.3)] z-[1001];
    transition: all .3s ease-in-out;

    &.active{
        @apply block opacity-100;
    }

    &-container{

        @apply bg-white w-full md:w-[50%] lg:w-[400px] h-full overflow-auto absolute top-0 left-[-100%] md:left-[-50%] lg:left-[-400px] z-[1002] transition-all ease-in-out duration-300;
        &.active{
            @apply left-0;
        }
    }

    &-img{
        @apply h-[100px] flex justify-center pb-6;

        img{
            @apply h-full;
        }
    }

    &-btngroup{
        @apply flex justify-between pb-8;
    }

    &-title{
        @apply flex items-center;
        span{
            @apply font-bold;
        }
    }

    &-desc{
        @apply pb-10;
    }

    &-number{
        @apply flex justify-around;

        &__container{
            @apply flex flex-col justify-center w-[120px] py-3 border-black  border border-solid rounded;
        }

        &__val, &__title{
            @apply flex justify-center;
        }

        &__title{
            span{
                @apply text-slate-400;
            }
        }

        &__val{
            span{
                @apply text-[1.8rem] lg:text-[3rem] text-red-700 font-bold;
            }
        }
    }

    &-third{
        @apply flex justify-center;
        &__wrapper{
            @apply w-[50%] border border-slate-200 border-solid py-4 hover:bg-slate-200;
            
        }
        &__label{
            @apply flex justify-center;
        }
        &__val{
            @apply flex justify-center;
            span{
                @apply text-red-600 font-bold text-2xl;
            }
        }
    }

    &-forth{
        @apply px-6 pt-4;
        &__wrapper{
            @apply flex pb-4;
        }

        &__icon {
            @apply pr-2 w-[10%];
        }

        &__label {
            @apply w-[80%];
            &.address span{
                @apply font-bold;
            }
        }
    }

    &-fifth{
        @apply pb-8;
        &__title{
            @apply font-bold text-xl px-2;
        }
        &__wrapper{
            @apply flex py-4 px-2 border-gray-200 border-[1px] border-solid;
        }

        &__logo{
            @apply w-[10%] flex items-center;
        }
        &__label{
            @apply w-[70%] flex items-center text-sm md:text-[16px];
        }
        &__name {
            @apply font-bold text-red-600;
        }
        &__badge{
            @apply w-[20%] flex items-center;
        }
    }
}
// locationcontainer :: end


// booking :: begin
.booking{
    &-bg{
        @apply  bg-scroll flex justify-center items-center bg-center bg-no-repeat bg-cover h-[100px] md:h-[200px];

        &__sitename{
            @apply text-white font-bold text-2xl md:text-4xl lg:text-7xl;
        }
    }

    &-main{
        @apply  w-full lg:w-[70%] min-h-[100px] p-4;
    }

    &-info{

    }

    &-custinfo{
        &__item{
            @apply flex pb-2;
        }
        &__label{
            @apply w-[20%];
        }
        &__val{
            @apply w-[80%];
        }

    }

    &-summary{
        @apply hidden lg:block w-[30%] min-h-[100px] p-4;
        &__detail{
            &-title{
                @apply pb-6 text-xl font-bold;
            }
            &-wrapper{
                @apply flex justify-between pb-2;
                .booking-summary__detail-val{
                    @apply font-bold;
                }
            }
            &-info{
                @apply pb-3;
            }
            &-total{
                @apply flex justify-between pb-4;
                .booking-summary__detail-label, .booking-summary__detail-val{
                    @apply text-lg;
                }
                .booking-summary__detail-val{
                    @apply font-bold;
                }
            }
        }
    }
}
// booking :: end

// order :: begin
.order{
    &-bg{
        @apply  h-[300px] lg:h-[450px] relative;
        &__video{
            video{
                @apply absolute top-0 left-0 block;
                width:100%;
                height:100%;
                object-fit: cover;
            }
        }

        &__content{
            @apply absolute w-[100%] top-0 px-2 lg:px-6 flex flex-col;
            
            span{
                @apply text-[16px] lg:text-[24px] tracking-[0.05em] leading-[1.8em] text-center text-white;
            }
        }
        &__img{
            @apply h-[200px] lg:h-[300px] flex justify-center;
            img{
                @apply h-full;
            }
        }
    }
}
// order :: end


// footer :: begin
.footer{
    @apply bg-white;
    &-container{
        @apply py-[2rem] px-8 xl:px-0 lg:mx-auto max-w-[1400px];
    }

    &-wrapper{
        @apply flex justify-between;
    }

    &-text{
        @apply flex items-center;
        &_copyright
        {

        }
    }

    &-logo{
        @apply h-[30px] xl:h-[60px];
        img{
            @apply h-full w-full;
        }
    }
}
// footer :: end

// babyloncanvas :: begin
#babylon-canvas{
    width: 100%;
    height: 100%;
}
// babyloncanvas :: end

// swiper :: begin
.swiper {
    width: 100%;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    @apply shadow-xl;

    @media (min-width: 1024px) {
        width:400px !important;
        height:400px !important;
    }
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    object-fit:contain;
    height:400px;
  }
// swiper :: end

// react-pdf :: begi
.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
}
// react-pdf :: end
